/**
 * Static helper methods for checking JS value types.
 * Especially useful for type guards
 */
export function isEmpty(obj: any): obj is null | undefined {
  return obj === void 0 || obj === null
}

export function isNotEmpty<T>(obj: T): obj is NonNullable<T> {
  return !isEmpty(obj)
}

export function isUndefined(obj: any): boolean {
  return obj === void 0
}

export function isString(obj: any): obj is string {
  return typeof obj === 'string'
}

export function isNumber(obj: any): obj is number {
  return typeof obj === 'number'
}

export function isBoolean(obj: any): obj is boolean {
  return typeof obj === 'boolean'
}

export function isArray(obj: any): obj is any[] {
  return Array.isArray(obj)
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function isFunction(obj: any): obj is Function {
  return obj instanceof Function
}

/** Returns true if valus is null/undefined or an empty string. Optionally, value can be trimmed before checking. */
export function isStringEmpty(
  value: string | null | undefined,
  trim = false
): value is null | undefined | '' {
  return (
    isEmpty(value) || (trim ? value.trim().length === 0 : value.length === 0)
  )
}

export function isPrimitive(obj: any): boolean {
  return !isJsObject(obj)
}

export function isJsObject(o: any): boolean {
  return !isEmpty(o) && (isFunction(o) || typeof o === 'object')
}
