import BigNumber from 'bignumber.js'

const DECIMAL_PRECISION = 2

/**
 * BigNumber rounding modes
 *
 * BN's rounding modes all have var names "ROUND_&lt;MODE&gt;"
 * To simplify translation we're using "&lt;MODE&gt;"" substring
 * and then concatenate to "ROUND_" prefix to get real BN value for mode
 */
type BigNumberRoundingMode = 'UP' | 'DOWN' | 'HALF_UP'

/**
 *
 * @param value - BugNumber value
 * @param decimalPlaces {nunmber} - defaults to 2
 * @param roundingMode {BigNumberRoundingMode} - based on BigNumber's rounding modes
 *
 * @returns string with formatted number
 */
export default function formatBN(
  value: BigNumber,
  decimalPlaces: number = DECIMAL_PRECISION,
  roundingMode?: BigNumberRoundingMode | undefined
): string {
  const mode =
    // translate to BN rounding mode
    roundingMode != null ? BigNumber[`ROUND_${roundingMode}`] : undefined

  if (value.isZero()) {
    return value.toFixed(decimalPlaces, mode)
  }
  if (value.gte('0.1')) {
    return value.toFormat(decimalPlaces, mode)
  } else if (value.lte('-0.1')) {
    return value.toFormat(decimalPlaces, mode)
  } else {
    return value.precision(decimalPlaces, mode).toFormat()
  }
}
