import { ButtonVariant } from './interface'
import get from 'lodash/get'
import styled from 'styled-components'
import { LARGE_BREAKPOINT } from 'ui/Common/components/Grid'

export const BackButton = styled.button<{ bgColor?: string }>`
  border: none;
  outline: none;
  cursor: pointer;
  user-select: none;

  height: 24px;

  overflow: hidden;

  border-radius: 4px;
  background-color: ${(p) => p.bgColor || p.theme.colors.grey.grey3};

  padding: 0;
  margin: 3px 0;

  font-size: 12px;

  line-height: 16px;
  letter-spacing: 1.2px;

  white-space: nowrap;

  display: flex;
  align-items: center;

  > span {
    display: none;
  }

  :hover {
    > span {
      display: initial;
      margin-right: 12px;
    }
  }

  :active {
    background-color: ${(p) =>
      p.bgColor ? p.theme.colors.grey.grey9 : p.theme.colors.grey.grey4};
  }
`

// don't use grays from the theme because backgrounds doesn't change with theme
export const Button = styled.button<{
  bgColor?: string
  color?: string
  variant?: ButtonVariant
}>`
  outline: none;
  user-select: none;
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.2px;
  transition: background-color 0.1s, color 0.1s;

  > span {
    display: flex;
    align-items: center;
  }

  ${(p) => {
    const secondaryShared = `
      padding: 0 16px;
      background-color: ${
        p.bgColor ? get(p.theme.colors, p.bgColor, p.bgColor) : 'transparent'
      };
      border-radius: 6px;
      color: ${
        p.color
          ? get(p.theme.colors, p.color, p.color)
          : p.theme.colors.grey.grey6
      };
      border: 2px solid ${
        p.color
          ? get(p.theme.colors, p.color, p.color)
          : p.theme.colors.grey.grey5
      };
      text-transform: uppercase;

      // make icon the same color as font
      svg {
        width: 18px;
        height: 18px;
        stroke: ${
          p.color
            ? get(p.theme.colors, p.color, p.color)
            : p.theme.colors.grey.grey6
        };
        fill: ${
          p.color
            ? get(p.theme.colors, p.color, p.color)
            : p.theme.colors.grey.grey6
        };
      }

      &:hover {
        background-color: ${
          p.color
            ? get(p.theme.colors, p.color, p.color)
            : p.theme.colors.grey.grey6
        };
        border-color: transparent;
        color: white;
      }

      &:active {
        background-color: ${
          p.color
            ? get(p.theme.colors, p.color, p.color)
            : p.theme.colors.grey.grey7
        };
        border-color: transparent;
        color: white;
      }

      &:disabled {
        cursor: initial;
        background-color: rgba(0, 0, 0, 0.3);

        &:hover {
          background-color: rgba(0, 0, 0, 0.3);
          color: ${
            p.color
              ? get(p.theme.colors, p.color, p.color)
              : p.theme.colors.grey.grey6
          };
        }
      }
    `

    switch (p.variant) {
      case ButtonVariant.Secondary:
        return `
          height: 28px;
          ${secondaryShared};
      `

      case ButtonVariant.SecondarySmall:
        return `
          height: 24px;
          ${secondaryShared};
        `

      case ButtonVariant.Text:
        return `
          text-transform: uppercase;
          border: none;
          padding: 8px;
          background-color: transparent;
          color: ${p.color ? get(p.theme.colors, p.color, p.color) : 'white'};

          // make icon the same color as font
          svg {
            width: 18px;
            height: 18px;
            stroke: ${
              p.color ? get(p.theme.colors, p.color, p.color) : 'white'
            };
            fill: ${p.color ? get(p.theme.colors, p.color, p.color) : 'white'};
          }
        `

      case ButtonVariant.Tertiary:
        return `
          border: none;
          height: 32px;
          padding: 0 18px;
          background-color: ${
            p.bgColor ? get(p.theme.colors, p.bgColor, p.bgColor) : 'white'
          };
          border-radius: 22.5px;
          color: ${
            p.color
              ? get(p.theme.colors, p.color, p.color)
              : p.theme.colors.grey.grey10
          };

          // make icon the same color as font
          svg {
            width: 18px;
            height: 18px;
            stroke: ${
              p.color
                ? get(p.theme.colors, p.color, p.color)
                : p.theme.colors.grey.grey10
            };
            fill: ${
              p.color
                ? get(p.theme.colors, p.color, p.color)
                : p.theme.colors.grey.grey10
            };
          }

          &:hover {
            background-color: ${p.theme.colors.grey.grey3};
          }

          &:active {
            background-color: ${p.theme.colors.grey.grey2};
          }

          &:disabled {
            cursor: initial;
            background-color: rgba(0, 0, 0, 0.3);

            &:hover {
              background-color: rgba(0, 0, 0, 0.3);
          }
      `

      case ButtonVariant.Primary:
      default:
        return `
          border: none;
          padding: 0 36px;
          height: 64px;

          @media (max-width: ${LARGE_BREAKPOINT}px) {
            height: 48px;
          }

          background-color: ${
            p.bgColor ? get(p.theme.colors, p.bgColor, p.bgColor) : 'black'
          };
          border-radius: 8px;
          color: ${p.color ? get(p.theme.colors, p.color, p.color) : 'white'};
          text-transform: uppercase;

          // make icon the same color as font
          svg {
            width: 18px;
            height: 18px;
            stroke: ${
              p.color ? get(p.theme.colors, p.color, p.color) : 'white'
            };
            fill: ${p.color ? get(p.theme.colors, p.color, p.color) : 'white'};
          }

          &:hover {
            background-color: ${p.theme.colors.grey.grey9}
          }

          &:active {
            background-color: ${p.theme.colors.grey.grey10};
          }

          &:disabled {
            cursor: initial;
            background-color: rgba(0, 0, 0, 0.3);

            > span {
            opacity: 0.5;
            }

            &:hover {
              background-color: rgba(0, 0, 0, 0.3);
          }
  }
    `
    }
  }};
`
