import styled from 'styled-components'

const RotatingContainer = styled.svg`
  animation: spin 1s ease-in-out infinite;
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`

export function AnimatedSpinnerIcon({ size }: { size?: number }) {
  return (
    <RotatingContainer
      width={size ?? 20}
      height={size ?? 20}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="#f9fafb"
    >
      <g stroke="#F9FAFB">
        <path d="M3.915 12.727a6.667 6.667 0 111.853 2.425" fill="none" />
      </g>
    </RotatingContainer>
  )
}
