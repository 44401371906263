import styled, { css, keyframes } from 'styled-components'
import { ElementPlacement } from 'ui/Common/util/hooks/useElementPlacement'

export const Tooltip = styled.div`
  display: inline-block;
  position: relative;
`

// animate tooltip visibility popup to look nicer AND to hide it before repositioning it
const PopupAnimation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

export const Popup = styled.div<{
  placement: ElementPlacement
  multiline?: boolean
}>`
  animation: ${PopupAnimation} 0.2s;

  min-width: 64px;
  max-width: 256px;
  position: absolute;
  width: ${(p) => (p.multiline ? '256px' : 'auto')};
  padding: 16px 20px;

  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.15);

  display: inline-flex;
  align-items: center;
  color: ${(p) => p.theme.colors.grey.grey10};

  z-index: 100;

  // ----- popup pointer (arrow/triangle created using borders)
  &::after {
    content: '';
    position: absolute;
    border: ${(p) => p.theme.spacing.xxs}px solid transparent;

    // ----- vertical placement

    // start-end
    ${(p) =>
      isPlacementVertical(p.placement) &&
      css`
        ${isPlacementCentered(p.placement) &&
        css`
          transform: translateX(-50%);
          left: 50%;
        `}
        ${isPlacementStart(p.placement) &&
        css`
          left: 12%;
        `}
        ${isPlacementEnd(p.placement) &&
        css`
          right: 12%;
        `}
      `}

    // top
    ${(p) =>
      p.placement.startsWith('top') &&
      css`
        top: 100%;
        border-top-color: white;
      `}
    // bottom
    ${(p) =>
      p.placement.startsWith('bottom') &&
      css`
        bottom: 100%;
        border-bottom-color: white;
      `}

    // ----- horizontal placement

    // start-end
    ${(p) =>
      isPlacementHorizontal(p.placement) &&
      css`
        ${isPlacementCentered(p.placement) &&
        css`
          transform: translateY(-50%);
          top: 50%;
        `}
        ${isPlacementStart(p.placement) &&
        css`
          top: 12%;
        `}
        ${isPlacementEnd(p.placement) &&
        css`
          bottom: 12%;
        `}
      `}

    // right
    ${(p) =>
      p.placement.startsWith('right') &&
      css`
        right: 100%;
        border-right-color: white;
      `}
    // left
    ${(p) =>
      p.placement.startsWith('left') &&
      css`
        left: 100%;
        border-left-color: white;
      `}
  }
`

function isPlacementVertical(placement: ElementPlacement) {
  return placement.startsWith('top') || placement.startsWith('bottom')
}
function isPlacementHorizontal(placement: ElementPlacement) {
  return placement.startsWith('right') || placement.startsWith('left')
}
function isPlacementStart(placement: ElementPlacement) {
  return placement.endsWith('start')
}
function isPlacementEnd(placement: ElementPlacement) {
  return placement.endsWith('end')
}
function isPlacementCentered(placement: ElementPlacement) {
  return !isPlacementStart(placement) && !isPlacementEnd(placement)
}
