import * as S from './styles'
import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import Box from 'ui/Common/components/Box'
import Text from 'ui/Common/components/Text'
import {
  ElementPlacement,
  useElementPlacement,
} from 'ui/Common/util/hooks/useElementPlacement'

const TOOLTIP_OVER_TIMEOUT_DEFAULT = 300 // in millis
const TOOLTIP_OUT_TIMEOUT_DEFAULT = 200 // in millis
const PLACEMENT_DEFAULT: ElementPlacement = 'top'

/**
 * Tooltip interface.
 */
type TooltipProps = React.PropsWithChildren<{
  /**
   * Alignment of the popup relative to the anchor element.
   */
  placement?: ElementPlacement
  /**
   * Time in miliseconds that mouse has to be over
   * the anchor element to trigger the popup.
   */
  delay?: number
  /**
   * Display full content instead of cliped single line.
   */
  multiline?: boolean
  /**
   * Content of the tooltip.
   */
  text: React.ReactNode
}>

/**
 * Tooltip container component.
 */
export default function Tooltip(props: TooltipProps) {
  const {
    delay = TOOLTIP_OVER_TIMEOUT_DEFAULT,
    placement = PLACEMENT_DEFAULT,
  } = props

  const [isMouseOver, setIsMouseOver] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  // dropdown container ref
  const triggerContainerRef = useRef<HTMLDivElement>(null)
  // popup container ref - used for positioning
  const popupContainerRef = useRef<HTMLDivElement>(null)
  const placeElement = useElementPlacement({ offset: 10 })

  // open/close popup with delay to allow hovering the popup itself (eg. to copy text from it)
  useEffect(() => {
    const timeoutDelay = isMouseOver ? delay : TOOLTIP_OUT_TIMEOUT_DEFAULT // use different delay for over and for out
    const timeoutId = setTimeout(() => {
      setIsOpen(isMouseOver)
    }, timeoutDelay)

    return () => clearTimeout(timeoutId)
  }, [delay, isMouseOver])

  // sync overlay position with trigger
  useEffect(() => {
    if (isOpen && triggerContainerRef.current && popupContainerRef.current) {
      placeElement(
        triggerContainerRef.current,
        popupContainerRef.current,
        props.placement ?? 'top'
      )
    }
  }, [isOpen, props.placement, placeElement])

  // ------ event handlers

  const handleMouseOver = useCallback(() => setIsMouseOver(true), [])

  const handleMouseOut = useCallback(() => setIsMouseOver(false), [])

  const handleMouseClick = useCallback((e: SyntheticEvent) => {
    e.stopPropagation()
    e.preventDefault()
    return false
  }, [])

  return (
    <S.Tooltip ref={triggerContainerRef}>
      <Box
        d="inline-block"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        {props.children}
      </Box>

      {isOpen &&
        createPortal(
          <div
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onClick={handleMouseClick}
          >
            <S.Popup
              placement={placement}
              multiline={props.multiline}
              ref={popupContainerRef}
            >
              <Text variant="paragraph.4" ellipsis={!props.multiline}>
                {props.text}
              </Text>
            </S.Popup>
          </div>,
          document.body
        )}
    </S.Tooltip>
  )
}
