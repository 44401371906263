import BigNumber from 'bignumber.js'
import React, { PropsWithChildren, useMemo } from 'react'
import Tooltip from 'ui/Common/components/Tooltip'
import {
  formatAmount,
  formatBalanceAmount,
  formatBalanceFiatAmount,
  formatFeeAmount,
  formatFeeFiatAmount,
  formatFiatAmount,
} from 'ui/Common/util/format/amountFormat'

export type AmountType = 'fee' | 'balance' | 'default'

// -- Prop types
// ----------

export interface AmountDisplayProps {
  /** Amount value */
  amount: BigNumber | undefined
  /**
   * Amount type. Determines which rules of formatting and rounding will be used.
   *
   * Possible value: 'fee', 'balance', 'default'
   *
   * See `ui/Common/util/format/amountFormat` for details
   */
  type: AmountType
  /** Should amount be rendered as fiat? Iow. should we add a currency symbol. Defaults to `false`. */
  fiat?: boolean
  /** Should we show tooltip with full amount? Defaults to `true`. */
  showTooltip?: boolean
  /** What to show if amount is invalid (empty, NaN, ...). Defaults to '-'. */
  fallback?: React.ReactNode
}

// -- Component
// ----------

/**
 * Display formatted amount value or fallback with optional tooltip.
 */
const AmountDisplay: React.FC<PropsWithChildren<AmountDisplayProps>> = ({
  amount,
  type,
  fiat = false,
  showTooltip = true,
  fallback: fallbackDisplay = '-',
}) => {
  const isAmountValid = amount != null && !amount.isNaN()

  const formattedAmount = useMemo(() => {
    if (isAmountValid) {
      if (type === 'fee') {
        if (fiat) {
          return formatFeeFiatAmount(amount)
        } else {
          return formatFeeAmount(amount)
        }
      } else if (type === 'balance') {
        if (fiat) {
          return formatBalanceFiatAmount(amount)
        } else {
          return formatBalanceAmount(amount)
        }
      } else {
        if (fiat) {
          return formatFiatAmount(amount)
        } else {
          return formatAmount(amount)
        }
      }
    }
  }, [amount, fiat, type, isAmountValid])

  return showTooltip && isAmountValid ? (
    <Tooltip text={amount.toFormat()}>{formattedAmount}</Tooltip>
  ) : (
    <>{formattedAmount ?? fallbackDisplay}</>
  )
}

export { AmountDisplay }
