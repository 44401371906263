import { BackButtonProps, ButtonProps } from './interface'
import * as S from './styles'
import React from 'react'
import { ArrowIcon } from 'ui/Icons/components/ArrowIcon'

/**
 * Basic button component. Content of a button is passed as `children` prop. This allows adding icons, etc.
 */
export const Button = ({ children, ...props }: ButtonProps) => {
  return (
    <S.Button type="button" {...props}>
      <span>{children}</span>
    </S.Button>
  )
}

/**
 * Back button component.
 */
export const BackButton = ({ bgColor, fill, ...props }: BackButtonProps) => {
  return (
    <S.BackButton bgColor={bgColor} type="button" {...props}>
      <ArrowIcon fill={fill} /> <span style={{ color: fill }}>Back</span>
    </S.BackButton>
  )
}
