import { useCallback } from 'react'

const PLACEMENT_OFFSET_DEFAULT = 8

export type ElementPlacement =
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end'

type PositionRect = {
  top: number
  right: number
  bottom: number
  left: number
}

/** Hook options */
export type ElementPlacementOptions = {
  /** Distance from anchore element along the main dimension. Number in pixels. Defaults to 8. */
  offset?: number
}

/**
 * Position one movable element ("floaty") around another fixed element ("anchor").
 *
 * Hook returns a positioning function that receives references to both elements
 * and a desired placement. This hook does not hold any internal state and is just
 * a convenience package for positioning fn.
 *
 * Placements are names for predefined relative positions (see `ElementPlacement` for available placements).
 * Each placement has a primary position (alignment) and an optional secondary position (justification).
 * Format is: `<primary>[-<secondary>]` Eg. `top`, `top-start`, `right-end`, ...
 *
 * Primary position determines on which side of an anchor a floaty will be aligned (top, right, bottom, left).
 *
 * Secondary position determines floaty's justification across primary side. For more consistency and easier
 * configuration, secondary positions are always relative: start, end or, if missing, center by default.
 * Similarly to CSS flexbox.
 *
 * Exmples:
 *  - `top` - above an anchor with aligned centers
 *  - `top-start` - above an anchor with aligned left sides
 *  - `right-start` - right of an anchor with aligned top sides
 *  - `left-end` - left of an anchor with aligned bottom sides
 *
 */
export function useElementPlacement(options?: ElementPlacementOptions) {
  const place = useCallback(
    (
      anchorEl: HTMLElement,
      floatyEl: HTMLElement,
      placement: ElementPlacement
    ) => {
      const anchorBoundingRect = anchorEl.getBoundingClientRect()
      const floatyBoundingRect = floatyEl.getBoundingClientRect()
      const placementOffset = options?.offset ?? PLACEMENT_OFFSET_DEFAULT // offset form trigger el

      // anchor element offsets of all 4 sides
      const anchorOffsetPosition: PositionRect = {
        top: anchorBoundingRect.top + window.scrollY,
        right: anchorBoundingRect.right + window.scrollX,
        bottom: anchorBoundingRect.bottom + window.scrollY,
        left: anchorBoundingRect.left + window.scrollX,
      }

      const elHorizPositionDiff =
        (anchorBoundingRect.width - floatyBoundingRect.width) / 2
      const elVertPositionDiff =
        (anchorBoundingRect.height - floatyBoundingRect.height) / 2

      const floatyOffsetRect: Partial<PositionRect> = {}

      // vertical
      if (placement.startsWith('top') || placement.startsWith('bottom')) {
        if (placement.startsWith('top')) {
          floatyOffsetRect.top =
            anchorOffsetPosition.top -
            floatyBoundingRect.height -
            placementOffset
        } else {
          floatyOffsetRect.top = anchorOffsetPosition.bottom + placementOffset
        }

        if (placement.endsWith('start')) {
          floatyOffsetRect.left = anchorOffsetPosition.left
        } else if (placement.endsWith('end')) {
          floatyOffsetRect.left =
            anchorOffsetPosition.right - floatyBoundingRect.width
        } else {
          floatyOffsetRect.left =
            anchorOffsetPosition.left + elHorizPositionDiff
        }
      }
      // horizontal
      else if (placement.startsWith('right') || placement.startsWith('left')) {
        if (placement.startsWith('right')) {
          floatyOffsetRect.left = anchorOffsetPosition.right + placementOffset
        } else {
          floatyOffsetRect.left =
            anchorOffsetPosition.left -
            floatyBoundingRect.width -
            placementOffset
        }

        if (placement.endsWith('start')) {
          floatyOffsetRect.top = anchorOffsetPosition.top
        } else if (placement.endsWith('end')) {
          floatyOffsetRect.top =
            anchorOffsetPosition.bottom - floatyBoundingRect.height
        } else {
          floatyOffsetRect.top = anchorOffsetPosition.top + elVertPositionDiff
        }
      } else {
        console.warn(`Unsupported placement ${placement}`)
      }

      floatyEl.style.top =
        floatyOffsetRect.top != null
          ? `${floatyOffsetRect.top?.toString()}px`
          : ''
      floatyEl.style.right =
        floatyOffsetRect.right != null
          ? `${floatyOffsetRect.right?.toString()}px`
          : ''
      floatyEl.style.bottom =
        floatyOffsetRect.bottom != null
          ? `${floatyOffsetRect.bottom?.toString()}px`
          : ''
      floatyEl.style.left =
        floatyOffsetRect.left != null
          ? `${floatyOffsetRect.left?.toString()}px`
          : ''
    },
    [options?.offset]
  )

  return place
}
