import React from 'react'

export const ArrowIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m8.352 11.864 6-3.75a.748.748 0 0 1 1.148.636v7.5a.75.75 0 0 1-1.148.636l-6-3.75a.75.75 0 0 1 0-1.272z"
      fill={props.fill || '#4D5461'}
      fillRule="nonzero"
    />
  </svg>
)
