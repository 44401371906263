import BigNumber from 'bignumber.js'
import formatBN from 'ui/Common/util/format/formatBN'
import { isEmpty } from 'ui/Common/util/lang/TypeUtils'

/**
 * Format general amount BigNumber value with defaults:
 *  - precision - 2
 *  - rounding - 'HALF_UP' (standard rounding)
 */
export function formatAmount(
  value: BigNumber.Value | undefined
): string | undefined {
  if (isEmpty(value)) return

  return formatBN(new BigNumber(value), 2, 'UP')
}

/**
 * Format balance amount BigNumber value with specific defaults:
 *  - precision - 2
 *  - rounding - 'DOWN'
 */
export function formatBalanceAmount(
  value: BigNumber.Value | undefined
): string | undefined {
  if (isEmpty(value)) return

  return formatBN(new BigNumber(value), 2, 'DOWN')
}

/**
 * Format fee amount BigNumber value with specific defaults:
 *  - precision - 2
 *  - rounding - 'UP'
 */
export function formatFeeAmount(
  value: BigNumber.Value | undefined
): string | undefined {
  if (isEmpty(value)) return

  return formatBN(new BigNumber(value), 2, 'UP')
}

/**
 * Format amount (see `formatAmount()`
 * and prefix it with currency symbol
 */
export function formatFiatAmount(
  value: BigNumber.Value | undefined
): string | undefined {
  if (isEmpty(value)) return

  return `$${formatAmount(value)}`
}

/**
 * Format balance amount (see `formatBalanceAmount()`
 * and prefix it with currency symbol
 */
export function formatBalanceFiatAmount(
  value: BigNumber.Value | undefined
): string | undefined {
  if (isEmpty(value)) return

  return `$${formatBalanceAmount(value)}`
}

/**
 * Format fee amount (see `formatFeeAmount()`
 * and prefix it with currency symbol
 */
export function formatFeeFiatAmount(
  value: BigNumber.Value | undefined
): string | undefined {
  if (isEmpty(value)) return

  return `$${formatFeeAmount(value)}`
}
